/* You can add global styles to this file, and also import other style files */
.modal-backdrop.fade
{
    opacity: 0.5;
}
.modal-open .modal
{
    opacity: 1;
}

.modal-dialog
{

  /* top: 105px; */
  /* top: 140px; */
  top: 60px;
}

/* Mat Autocomplete on Modal Fixes */ .cdk-overlay-container {z-index: 999999 !important;}

html, body { height: 100% }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
